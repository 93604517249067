<template>
  <div>
    <div class="row">
      <div class="col-xl-8 col-lg-8 col-md-12">
        <BalanceList />
      </div>
      <div class="col-xl-4 col-lg-4 col-md-12">
        <div
          v-if="isLoading"
          class="card text-center"
          style="min-height: 417px; opacity: 0.8;"
        >
          <div class="card-content">
            <div class="card-body">
              <b-spinner
                variant="primary"
                type="grow"
                label="Spinning"
              ></b-spinner>
            </div>
          </div>
        </div>
        <div class="card" v-else>
          <div class="card-content">
            <div class="card-body">
              <div class="text-center clearfix mb-2 chart-parent">
                <apexchart
                  type="donut"
                  :options="chartOptions"
                  :series="series"
                  height="100%"
                ></apexchart>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import BalanceList from "@/components/McmWallet/BalanceList.vue";
import { SET_BREADCRUMB } from "@/store/modules/breadcrumbs";

export default {
  name: "Wallet",
  components: {
    BalanceList
  },
  computed: {
    ...mapState({
      series: state => state.mcmWallet.chartSeries,
      chartOptions: state => {
        if (state.mcmWallet.chartSeries.length > 9) {
          return {
            ...state.mcmWallet.chartOptions,
            stroke: {
              width: 0
            }
          };
        }
        return state.mcmWallet.chartOptions;
      },
      isLoading: state => state.mcmWallet.isLoading
    })
  },
  methods: {
    ...mapActions("mcmWallet", ["FETCH_BALANCES"])
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("ROUTES.WALLET") }]);
  },
  created() {
    this.FETCH_BALANCES(this.$route.params?.companyId);
  }
};
</script>
<style lang="scss" scoped>
.chart-parent {
  min-height: 350px;
}
</style>
