<template>
  <div id="wallet">
    <div class="d-flex flex-row-reverse mb-1" v-if="!isError.status">
      <div class="form-check">
        <input
          type="checkbox"
          class="form-check-input"
          id="hide-low-balances"
          v-model="hideLowBalances"
          :disabled="isLoading"
        />
        <span v-if="isLoading" class="font-weight-bold text-muted">
          {{ $t("COMMON.hide_low_balances") }}
        </span>
        <span
          v-else
          @click="onClickHideLowBalances"
          class="font-weight-bold hide_low_balances_lable"
          >{{ $t("COMMON.hide_low_balances") }}</span
        >
      </div>
    </div>
    <!-- error state -->
    <section
      v-if="!isLoading && isError.status"
      class="card text-center"
      style="min-height: 100px;"
    >
      <div class="card-content">
        <div class="card-body">
          <div class="d-flex flex-column align-items-center">
            <span class="mb-2">
              <b-icon icon="exclamation-triangle" variant="danger"></b-icon>
              {{ isError.message }}
            </span>
            <b-button
              variant="outline-primary"
              class="w-50"
              @click="refetchBalances"
              >{{ $t("COMMON.refresh") }}</b-button
            >
          </div>
        </div>
      </div>
    </section>
    <!-- isLoading state -->
    <template v-if="isLoading">
      <section
        class="card mb-2 text-center"
        v-for="index in 10"
        :key="'loader-' + index"
        style="min-height: 100px; opacity: 0.8;"
      >
        <div class="card-content">
          <div class="card-body">
            <b-spinner
              variant="primary"
              type="grow"
              label="Spinning"
            ></b-spinner>
          </div>
        </div>
      </section>
    </template>
    <!-- data -->
    <template v-else>
      <section
        class="card pull-up mb-2"
        v-for="(item, index) in balanceData"
        :key="'wallet-' + index"
      >
        <div class="card-content">
          <div class="card-body">
            <div class="col-12">
              <div class="row justify-content-md-center align-items-md-center">
                <div class="col-12 col-md-7">
                  <div class="media align-items-center">
                    <img
                      :src="item.currency_icon"
                      class="cc mr-2 font-large-2 warning d-none d-md-block"
                      height="30"
                      alt=""
                    />
                    <div class="media-body">
                      <div class="mt-0 text-capitalize currency-name">
                        {{ item.currency_name }}
                        {{ item.currency_code }}
                      </div>
                      <template v-if="item.wallets && item.wallets.length > 0">
                        <div
                          class="text-muted font-size-small wallet-address d-flex flex-column"
                          v-for="(wallet, walletIdx) in item.wallets"
                          :key="`wallet-${walletIdx}`"
                        >
                          <span
                            v-if="wallet.address"
                            style="margin-bottom: 5px;"
                          >
                            <strong>{{ wallet.currency }}</strong
                            >: {{ wallet.address }}
                          </span>
                          <span
                            v-if="['XRP', 'XLM'].includes(item.currency_code)"
                          >
                            <strong>{{
                              item.currency_code == "XRP"
                                ? "Etiket"
                                : "Memo Adres"
                            }}</strong>
                            {{ wallet.tag }}
                          </span>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-5 text-md-right">
                  <div class="available-name">
                    {{ item.available_formatted }}
                  </div>
                  <p class="text-muted mb-0 font-small-3">
                    {{ item.default_currency_total_formatted }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "BalanceList",
  data() {
    return {
      hideLowBalances: false
    };
  },
  computed: {
    ...mapState({
      isLoading: state => state.mcmWallet.isLoading,
      balances: state => state.mcmWallet.balances,
      isError: state => state.mcmWallet.isError
    }),
    balanceData() {
      if (this.hideLowBalances) {
        return this.balances.filter(balance => Number(balance?.available) > 0);
      }
      return this.balances;
    }
  },
  methods: {
    onClickHideLowBalances() {
      this.hideLowBalances = !this.hideLowBalances;
    },
    refetchBalances() {
      this.$store.dispatch(
        "mcmWallet/FETCH_BALANCES",
        this.$route.params?.companyId
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.currency-name {
  font-weight: bold;
  font-size: 20px;
}
.available-name {
  font-weight: bold;
  font-size: 1.1rem;
}

.hide_low_balances_lable:hover {
  cursor: pointer;
  color: #000;
}

#wallet .cc {
  line-height: 3rem;
}

#wallet .wallet-address {
  word-break: break-all;
}

#wallet .la.rounded-circle {
  font-size: 2rem;
  padding: 0.5rem;
}
</style>
